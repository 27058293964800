<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Widgets from "./components/widget.vue";
import Chart from "./components/Chart.vue";
import CongestionChart from './components/congestion-chart.vue';
import { mapGetters } from "vuex";
import { useBreakpoints, breakpointsTailwind, useFullscreen } from '@vueuse/core'
import { ref } from "vue";


export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: '' }],
  },
  setup() {
    const breakpoints = useBreakpoints(breakpointsTailwind)
    const congestionCtn = ref(null)
    const { isFullscreen } = useFullscreen(document.body)

    return {
      breakpoints,
      congestionCtn,
      isFullscreen
    }
  },
  components: {
    Layout,
    PageHeader,
    Widgets,
    Chart,
    CongestionChart,
  },
  data() {
    return {
      title: this.$t("t-dashboard"),
      items: [],
      date: null,
      peopleIn: 0,
      height: 450,
      hasRooms: false,
    }
  },
  computed: {
    refreshData() {
      return localStorage.getItem('refresh') ? JSON.parse(localStorage.getItem('refresh')) : false;
    },
    secondRefresh() {
      return localStorage.getItem('second')
    },
    ...mapGetters({
      facilityId: 'settings/getFacilityId',
      fullscreen: 'settings/getFullScreen'
    }),
  },
  mounted() {
    if (this.refreshData) {
      this.$store.commit('settings/SET_REFRESH_DATA', this.refreshData);
    }
    if (this.secondRefresh) {
      this.$store.commit('settings/SET_SECONDS', this.secondRefresh * 1);
    }
  },
  methods: {
    onChangeDate(date) {
      this.date = date;
    },
    getPeopleIn(peopleIn) {
      this.peopleIn = peopleIn || 0;
    },
    getHasRooms(hasRooms) {
      const defH = this.fullscreen ? 605 : 485
      this.hasRooms = hasRooms;
      this.height = hasRooms ? defH : defH + 150
    }
  },
  watch: {
    fullscreen: {
      handler(on) {
        const defH = on ? 605 : 485
        this.height = this.hasRooms ? defH : defH + 150
      }
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row project-wrapper mt-n2">

      <div class="col-md-12">
        <div class="row" ref="congestionCtn">
          <div class="col-xl-8 col-md-12 col-xs-12 col-sm-12 gap-1 left-dashboard">
            <Widgets :facilityId="facilityId" @peopleIn="getPeopleIn" @getHasRooms="getHasRooms" />
            <Chart :facilityId="facilityId" :height="height" :peopleIn="peopleIn" @onChangeDate="onChangeDate"
              :dateDefault="date" />
          </div>

          <div class="col-xl-4 col-md-12 col-xs-12 col-sm-12 right-dashboard">
            <div class="d-flex flex-column gap-circle w-100">
              <congestion-chart :fullscreen="fullscreen" :facilityId="facilityId" :isYesterday="true"
                :headerText="$t('congestion.yesterday')" />
              <congestion-chart :fullscreen="fullscreen" :facilityId="facilityId" :isYesterday="false"
                :headerText="$t('congestion.lastWeek')" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style lang="scss" scoped>
  .gap-circle {
    gap: 0.7rem !important;
  }

  .row > .left-dashboard {
    padding-right: 0;
  }

  .row > .right-dashboard {
    padding-left: 0.7rem;
  }

  // .row > .right-dashboard {
  //   padding-left: calc(var(--vz-gutter-x) * .5);
  // }
</style>
