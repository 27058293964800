
<template>
    <div class="card rounded-3 congestion-card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h6 class="fs-16 m-0 p-0">{{ headerText }}</h6>
            <div class="d-flex gap-1 align-items-center">
                <b-button size="sm" @click="() => change('all')" :variant="getVariant('all')">{{
                    $t('congestion.all')
                }}</b-button>
                <b-button size="sm" @click="() => change('saunaMen')" :variant="getVariant('saunaMen')">{{
                    $t('congestion.saunaMen') }}</b-button>
                <b-button size="sm" @click="() => change('saunaWomen')" :variant="getVariant('saunaWomen')">{{
                    $t('congestion.saunaWomen') }}</b-button>
            </div>
        </div>
        <div class="card-body" :style="`height: ${state.fixedHeight}px;`">
            <apexchart class="apex-charts" :height="state.chartHeight" dir="ltr" :options="state.doughnutChart.chartOptions"
                :series="state.doughnutChart.series" />
        </div>
    </div>
</template>

<script>
import { isEqual, keys, round, sum, toPairsIn } from 'lodash'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import i18n from '@/i18n'
import { sensorLogsService } from '@/services'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
    props: {
        isYesterday: {
            type: Boolean,
            default: false
        },
        headerText: {
            type: String,
            default: i18n.global.t('congestion.yesterday')
        },
        facilityId: {
            type: String,
            default: ''
        },
        fullscreen: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const initCongestionDefault = {
            bluePart: {
                percent: null,
                totalPeopleCount: 0
            },
            yellowPart: {
                percent: null,
                totalPeopleCount: 0
            },
            redPart: {
                percent: null,
                totalPeopleCount: 0
            },
            grayPart: {
                percent: null,
                totalPeopleCount: 0
            }
        }

        const i18nMapLabel = {
            bluePart: 'congestion.morning',
            yellowPart: 'congestion.daytime',
            redPart: 'congestion.night',
            grayPart: 'congestion.other',
        }

        const timeMapper = {
            bluePart: '05:00-09:00',
            yellowPart: '09:00-17:00',
            redPart: '17:00-24:00',
            grayPart: '24:00-05:00',
        }

        const dataPointIndexRef = ref(-1)

        const defaultChartOption = {
            chartOptions: {
                tooltip: {
                    x: {
                        show: true,
                        formatter: (seriesName) => {
                            let key = ''
                            if (seriesName.includes(timeMapper.bluePart)) {
                                key = 'congestion.morningUse'
                            } else if (seriesName.includes(timeMapper.yellowPart)) {
                                key = 'congestion.dayUse'
                            } else if (seriesName.includes(timeMapper.redPart)) {
                                key = 'congestion.nightUse'
                            } else {
                                key = 'congestion.otherUse'
                            }
                            return i18n.global.t(key)
                        },
                    },
                    y: {
                        formatter: (value, { config, seriesIndex }) => {
                            const total = formatter(config.mappers[seriesIndex].totalPeopleCount)
                            return `${total}${i18n.global.t('congestion.person')}`
                        },
                        title: {
                            formatter: (seriesName) => {
                                let key = ''
                                if (seriesName.includes(timeMapper.bluePart)) {
                                    key = 'congestion.morningUse'
                                } else if (seriesName.includes(timeMapper.yellowPart)) {
                                    key = 'congestion.dayUse'
                                } else if (seriesName.includes(timeMapper.redPart)) {
                                    key = 'congestion.nightUse'
                                } else {
                                    key = 'congestion.otherUse'
                                }
                                return i18n.global.t(key)
                            },
                        },
                    },
                },
                mappers: [],
                chart: {

                    type: "donut",
                    events: {
                        dataPointMouseEnter: (e, d, { dataPointIndex }) => {
                            dataPointIndexRef.value = dataPointIndex
                        },
                        dataPointMouseLeave: () => {
                            dataPointIndexRef.value = -1
                        }
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: true,
                    dropShadow: {
                        enabled: false,
                    },
                    style: {
                        fontSize: "10px"
                    }
                },
                noData: {
                    text: i18n.global.t('t-no-data')
                },
                legend: {
                    position: "bottom",
                    customLegendItems: [],
                    fontSize: "10px"
                },
                colors: ["#6691E7", "#E8BC52", "#ED5E5E", "#A1A1A1"],
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '10px',
                                    color: '#A1A1A1',
                                    formatter: () => {
                                        return i18n.global.t('congestion.totalInfo')
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    formatter: (value, { config }) => {
                                        const total = formatter(sum(config.mappers.map(m => m.totalPeopleCount)))
                                        return `${total}${i18n.global.t('congestion.person')} `
                                    }
                                },
                                total: {
                                    show: true,
                                    fontSize: '12px',
                                    color: '#A1A1A1',
                                    label: i18n.global.t('congestion.totalInfo'),
                                    formatter: ({ config }) => {
                                        const total = formatter(sum(config.mappers.map(m => m.totalPeopleCount)))
                                        return `${total}${i18n.global.t('congestion.person')} `
                                    }
                                }
                            }
                        }
                    }
                }
            },
            series: [],
        }

        const state = reactive({
            chartData: {
                ...initCongestionDefault
            },
            doughnutChart: { ...defaultChartOption },
            filterType: 'all',
            hasValue: false,
            fixedHeight: 311,
            chartHeight: 280
        })

        const store = useStore()
        const route = useRoute()
        const refreshData = computed(() => store.getters['settings/getRefreshData'])
        const secondRefresh = computed(() => store.getters['settings/getSeconds'])
        const intervalRefreshData = ref()

        const getVariant = (type = 'all') => {
            if (isEqual(state.filterType, type)) {
                return 'primary'
            } else {
                return 'light'
            }
        }

        const change = (type = 'all') => {
            state.filterType = type
        }

        const formatter = quantity => new Intl.NumberFormat('en-US').format(quantity)

        const getLegendsAndSeries = (data) => {
            const lKeys = keys(data)
            const legends = lKeys.map(l => `${i18n.global.t(i18nMapLabel[l])}\n${timeMapper[l]}`)
            const mappers = toPairsIn(data).map(pair => ({ time: timeMapper[pair[0]], name: i18n.global.t(i18nMapLabel[pair[0]]), value: round(pair[1].percent || 0, 1), totalPeopleCount: pair[1].totalPeopleCount }))

            const series = mappers.map(m => m.value)
            const hasValue = series.some(s => !isEqual(s, 0))

            return {
                legends,
                series: hasValue ? series : [],
                mappers,
                hasValue
            }
        }

        const ROOMTYPE = {
            all: null,
            saunaMen: 'SAUNA_ROOM_MALE',
            saunaWomen: 'SAUNA_ROOM_FEMALE'
        }

        const getRoomType = (filterType) => {
            return ROOMTYPE[filterType]
        }

        const getCongestionSituation = async () => {
            if (!props.facilityId) return
            const params = {
                facilityId: props.facilityId,
                isDay: props.isYesterday ? true : false,
                roomType: getRoomType(state.filterType)
            }
            const response = await sensorLogsService.getCongestionSituationDashboard(params)
            if (response) {
                state.chartData = response
                const { legends, series, mappers, hasValue } = getLegendsAndSeries(state.chartData)
                const legend = {
                    ...state.doughnutChart.chartOptions.legend,
                    customLegendItems: legends,
                    show: hasValue
                }
                state.hasValue = hasValue
                const height = hasValue ? 320 : 280
                const fullscreen = props.fullscreen
                state.chartHeight = fullscreen ? height + 60 : height
                state.fixedHeight = fullscreen ? 311 + 60 : 311
                state.doughnutChart = {
                    chartOptions: {
                        ...state.doughnutChart.chartOptions,
                        mappers,
                        labels: legends,
                        legend,
                    },
                    series
                }

            }
        }

        onMounted(() => {
            if (refreshData.value && secondRefresh.value && (secondRefresh.value * 1 > 29)) {
                intervalRefreshData.value = setInterval(() => {
                    getCongestionSituation();
                }, secondRefresh.value * 1000);
            } else {
                clearInterval(intervalRefreshData.value);
            }
            getCongestionSituation()
        })

        watch(() => props.facilityId, () => {
            getCongestionSituation()
        })

        watch(() => [state.filterType], () => {
            getCongestionSituation()
        })

        watch(route, () => {
            clearInterval(intervalRefreshData.value)
        }, { deep: true })

        watch(() => props.fullscreen, (on) => {
            const defH = state.hasValue ? 320 : 280
            state.chartHeight = on ? defH + 60 : defH
            state.fixedHeight = on ? 310 + 60 : 310
        })

        watch(refreshData, (on) => {
            if (on) {
                if (secondRefresh.value && (secondRefresh.value * 1 > 29)) {
                    intervalRefreshData.value = setInterval(() => {
                        getCongestionSituation();
                    }, secondRefresh.value * 1000);
                }
            } else {
                clearInterval(intervalRefreshData.value);
            }
        })

        return {
            state,
            getVariant,
            change
        }
    },
}
</script>

<style scoped lang="scss">
.congestion-card {
    & .card-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    border-radius: 6px;
    margin-bottom: 0;

    & button {
        font-size: 12px !important;
    }

    & .btn-light {
        color: #A1A1A1 !important;
    }
}

.apex-charts:deep(text) {
    white-space: pre-line !important;
    fill: #adb5bd;
}

.apex-charts:deep(span) {
    white-space: pre-line !important;
}

.apex-charts:deep(.apexcharts-datalabel-value) {
    font-size: 16px !important;
    fill: #000 !important;
}

[data-layout-mode="dark"] {
    .apex-charts:deep(.apexcharts-datalabel-value) {
        fill: white !important;
    } 
}

.apex-charts:deep(.apexcharts-datalabel-label) {
    color: #A1A1A1 !important;
    fill: #A1A1A1 !important;
    font-size: 9px;
}

.apex-charts:deep(.apexcharts-legend-text) {
    text-align: center;
    font-size: 12px !important;
}
</style>
