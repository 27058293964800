<template>
  <div v-if="!loading && state.roomInfo.length > 0" class="row dashboard-widget" @scroll="onScroll">
    <div class="col-xl-6 col-xs-12 col-md-6" v-for="item of state.roomInfo" :key="item.id"
      :class="item.textColor">
      <div class="card card-animate">
        <div class="card-body" :class="`${item.backgroundClass} rd6`">
          <div class="d-flex align-items-center">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title rounded-2 fs-2" :class="{
                'badge-white text-primary': item.feaIconClass,
                'badge-grey text-warning': !item.feaIconClass,
              }">
                <template v-if="item.feaIcon">
                  <img :src="item.feaIcon" alt="">
                </template>
              </span>
            </div>
            <div class="flex-grow-1 overflow-hidden ms-3">
              <p class="fw-medium text-truncate mb-3">
                {{ $t(item.label) }}：{{ item.description }}
              </p>
              <div class="d-flex align-items-center mb-3 w-100 justify-content-between">
                <div class="widget-content">
                  <span class="content-title mr-2">
                    {{ item.counter }}
                  </span>
                  <span>{{ `${$t('t-name2')} / ${item.subLabel}${$t('t-name2')}` }}</span>
                </div>
                <span class="badge badge-widget fs-14" :class="{
                  'badge-white': item.backgroundClass,
                  'badge-grey': !item.backgroundClass
                }">
                  <span>{{ item.percent }}</span>
                </span>
              </div>
              <p class="text-truncate mb-0">{{ $t('totalPeopleUse', { total: item.personComeIn }) }}
              </p>
            </div>
          </div>
        </div>
        <!-- end card body -->
      </div>
    </div>
  </div>
</template>

<script>
import { roomService } from "@/services/room.service";
import { round, sum } from "lodash";
import { ref, onMounted, watch, computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { sensorLogsService } from "../../../services";

export default {
  props: {
    facilityId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {

    const projectsWidgets = ref([]);

    const store = useStore();
    const route = useRoute();
    const loading = ref(false);

    const totalPage = ref(0);
    const currentPage = ref(0);
    const intervalRefreshData = ref();

    const refreshData = computed(() => store.getters['settings/getRefreshData']);
    const secondRefresh = computed(() => store.getters['settings/getSeconds']);

    const params = reactive({
      page: 1,
      pageSize: 10,
      subType: 'SAUNA_DRIED',
      facilityId: props.facilityId,
    });

    const state = reactive(
      {
        roomInfo: []
      }
    )

    /**
     *
     * @param {Number} percent
     */
    const getBackgroundByPercent = (percent) => {
      if (percent < 40) return 'bg-info'
      else if (percent >= 40 && percent < 60) return 'bg-success'
      else if (percent >= 60 && percent < 80) return 'bg-warning'
      else return 'bg-danger'
    }

    const getTextRoomType = (roomType) => {
      if(roomType === 'SAUNA_ROOM_MALE') return 't-men-bath-room'
      else if(roomType === 'SAUNA_ROOM_FEMALE') return 't-women-bath-room'
      else {
        return 'OTHER'
      }
    }

    const getRoomsInfo = async (type) => {
      if (!props.facilityId) return;
      try {
        if (!type || type !== 'refresh') {
          loading.value = true;
        }
        const res = await sensorLogsService.getRoomsInfo({ facilityId: props.facilityId });
        state.roomInfo = res.map(d => ({
          ...d,
          feaIcon: require("@/assets/images/dashboard/img345.png"),
          feaIconClass: "success",
          textColor: 'text-white',
          label: getTextRoomType(d.type),
          counter: d.currentPeopleCount,
          subLabel: d.capacity,
          description: d.roomName,
          suffix: "",
          badgeClass: "danger",
          icon: "ri-arrow-down-s-line",
          percent: `${round(d.percent)}%`,
          backgroundClass: getBackgroundByPercent(d.percent),
          personComeIn: d.personComeIn || 0
        }))
        loading.value = false;
      } catch (e) {
        state.roomInfo = []
        loading.value = false;
      }

      const peopleIn = sum(state.roomInfo.map(r => r.personComeIn))
      emit('peopleIn', peopleIn)
      emit('getHasRooms', state.roomInfo.length > 0)
    }

    // Function get all rooms dashboard
    const getRoomsDashboard = async (type) => {

      if (!props.facilityId) return;

      try {
        if (!type || type !== 'refresh') {
          loading.value = true;
        }
        let res = await roomService.getRoomsDashboard({ ...params, facilityId: props.facilityId });
        if (res) {
          totalPage.value = res?.pagination?.totalPage;
          currentPage.value = res?.pagination?.currentPage;
          let widgets = res.data.map(r => {
            let percentage = r.percent;
            return {
              id: r.id,
              feaIcon: require("@/assets/images/dashboard/img345.png"),
              feaIconClass: "success",
              textColor: 'text-white',
              label: getTextRoomType(r.type),
              counter: r.currentPeopleCount,
              subLabel: r.capacity,
              description: r.roomName,
              suffix: "",
              badgeClass: "danger",
              icon: "ri-arrow-down-s-line",
              percentage: `${percentage}%`,
              backgroundClass: percentage < 40 ? 'info' : (40 <= percentage && percentage < 60) ? 'success' : (60 <= percentage && percentage < 80) ? 'warning' : 'error',
              peopleIn: r.peopleIn || 0
            }
          });

          const peopleIn = sum(widgets.map(r => r.peopleIn))
          emit('peopleIn', peopleIn)



          for (let index = 0; index < widgets.length; index++) {
            const element = widgets[index];
            let findIndex = projectsWidgets.value.findIndex(w => w.id === element.id);
            if (findIndex !== -1) {
              projectsWidgets.value[findIndex] = element;
            } else {
              projectsWidgets.value = [...projectsWidgets.value, element];
            }
          }

        }
        loading.value = false;
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    watch(route, (to) => {
      if (to.name.value !== 'default') {
        clearInterval(intervalRefreshData.value);
      }
    }, { flush: 'pre', immediate: true, deep: true })

    watch(refreshData, (data) => {
      if (!data) {
        clearInterval(intervalRefreshData.value);
      } else {
        if (refreshData.value && secondRefresh.value && (secondRefresh.value * 1 > 29)) {
          intervalRefreshData.value = setInterval(() => {
            //getRoomsDashboard('refresh');
            getRoomsInfo('refresh')
          }, secondRefresh.value * 1000);
        } else {
          clearInterval(intervalRefreshData.value);
        }
      }
    })

    onMounted(() => {
      if (refreshData.value && secondRefresh.value && (secondRefresh.value * 1 > 29)) {
        intervalRefreshData.value = setInterval(() => {
          //getRoomsDashboard('refresh');
          getRoomsInfo('refresh')
        }, secondRefresh.value * 1000);
      } else {
        clearInterval(intervalRefreshData.value);
      }
      //getRoomsDashboard();
      getRoomsInfo()

    });

    watch(() => props.facilityId, () => {
      projectsWidgets.value = []
      //getRoomsDashboard()
      getRoomsInfo()
    })

    // Function onScroll
    const onScroll = (e) => {
      const el = e.srcElement;

      if (!loading.value && (Math.abs(el.scrollLeft + 10)) > el.scrollWidth - el.clientWidth) {
        if (currentPage.value < totalPage.value) {
          params.page += 1;
        }
      }
    }

    return {
      loading,
      projectsWidgets,
      onScroll,
      state,
    };
  },
  components: {
  },
};
</script>

<style lang="scss">
.dashboard-widget {
  font-size: 14px;
  flex-wrap: nowrap;
  overflow: auto;

  .widget-content {
    .content-title {
      font-size: 24px;
    }

  }

  .card {
    margin-bottom: 0.7rem;
  }

  .rd6 {
    border-radius: 6px;
  }

  .badge-widget {
    padding: 10px 20px;
  }

  .badge-white {
    background: rgba(255, 255, 255, 0.4);
    color: white;
  }

  .badge-grey {
    background: rgba(222, 219, 219, 0.4);
    color: #495057;
  }

  .bgMedium {
    background: #D59231;
  }

  .bgEmpty {
    background: #2D62AE;
  }

  .bgLarge {
    background: #C0272D;
  }

  .bgNormal {
    background: #3B7E44;
  }
}
</style>
