<template>
  <div class="card dashboard-chart">
    <div v-if="loading" class="d-flex justify-content-center" style="padding: 20px 0">
      <Loading />
    </div>
    <div v-else>
      <div class="card-header flex align-items-center justify-content-between">
        <div class="card-title mb-0 fs-18">
          {{ $t("t-congestion") }}
        </div>
        <div class="flex align-items-center">
          <div class="form-control flatpickr-input peopleIn input active">{{ $t('totalPeopleIn', {
            peopleIn:
              formatter(peopleIn)
          }) }}</div>
        </div>
        <div class="flex align-items-center">
          <div>
            <i @click="onChangeDate('prev')" class="fs-18 icon-change-date mdi mdi-chevron-left"></i>
          </div>
          <flat-pickr v-model="date" :config="config"
            class="form-control flatpickr-input select-date-picker"></flat-pickr>
          <div>
            <i @click="onChangeDate('next')" class="fs-18 icon-change-date mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- end card header -->
      <div class="card-body">
        <apexchart v-if="!loading" class="apex-charts" :height="height" dir="ltr" ref="apex-chart" :series="series"
          :options="chartOptions" @zoomed="onZoomed" @scrolled="onScrolled" @beforeResetZoom="onBeforeResetZoom">
        </apexchart>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import { sensorLogsService, facilityService } from "@/services";
import flatPickr from "vue-flatpickr-component";
import Loading from "@/components/Loading.vue";

import "flatpickr/dist/flatpickr.css";
import { mapGetters } from "vuex";
import { last } from 'lodash';

export default {
  name: 'ChartDashboard',
  props: {
    dateDefault: {
      type: String,
      default: ''
    },
    peopleIn: {
      type: Number,
      default: 0
    },
    facilityId: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 485
    }
  },
  components: {
    flatPickr,
    Loading
  },
  data() {
    return {
      loading: false,
      chartData: [],
      params: {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      date: moment().get('hours') >= 5 ? moment(new Date()).format('YYYY-MM-DD') : moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
      config: {
        wrap: true,
        altFormat: "Y-m-d",
        altInput: true,
        dateFormat: "Y-m-d",
        disable: [
          function (date) {
            // return true to disable
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
          }
        ]
      },
      series: [],
      chartOptions: {
        chart: {
          type: "area",
          stacked: false,
          redrawOnParentResize: true,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false, // Show labels for note
        },
        markers: {
          size: 0,
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          offsetY: 20,
          height: 64
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          showAlways: true,
          min: 0,
          max: 120,
          tickAmount: 6,
          title: {
            style: {
              fontWeight: 500,
            },
          },
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return moment(new Date(val)).format('HH:mm');
            },
          },
        },
        noData: {
          text: this.$t('t-no-data'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            color: '#ced4da'
          }
        },
      },
      isLoading: false,
      facility: null
    }
  },
  watch: {
    height() {
    },
    facilityId: {
      handler() {
        this.loadChartData();
        this.getFacility();
      }
    },
    date: {
      handler() {
        this.$emit('onChangeDate', this.date);
        this.loadChartData();
      },
      deep: true
    },
    dateDefault: {
      handler() {
        if (this.dateDefault) {
          this.date = this.dateDefault;
        }
      },
      deep: true
    },
    '$route': function () {
      clearInterval(this.intervalRefreshData);
    },
    refreshData() {
      if (this.refreshData) {
        if (this.secondRefresh && (this.secondRefresh * 1 > 29)) {
          this.intervalRefreshData = setInterval(() => {
            this.loadChartData('refresh');
          }, this.secondRefresh * 1000);
        }
      } else {
        clearInterval(this.intervalRefreshData);
      }
    }
  },
  computed: {
    ...mapGetters({
      refreshData: 'settings/getRefreshData',
      secondRefresh: 'settings/getSeconds',
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    if (this.facilityId) {
      if (this.refreshData && this.secondRefresh && (this.secondRefresh * 1 > 29)) {
        this.intervalRefreshData = setInterval(() => {
          this.loadChartData('refresh');
        }, this.secondRefresh * 1000);
      } else {
        clearInterval(this.intervalRefreshData);
      }
      this.loadChartData();
      this.getFacility();
      console.log(this.facilityId, '==========')

    }
  },
  methods: {
    async getFacility() {
      if (!this.facilityId) return
      this.facility = await facilityService.getAdminFacility(this.facilityId)
    },
    formatter: quantity => new Intl.NumberFormat('en-US').format(quantity),
    onChangeDate(type) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (type === 'prev') {
        this.date = moment(new Date(this.date)).subtract(1, 'days').format('YYYY-MM-DD');
      } else {
        if (new Date(this.date) < today) {
          this.date = moment(new Date(this.date)).add(1, 'days').format('YYYY-MM-DD');
        }
      }
    },
    async loadChartData(type) {
      const vm = this;

      if (!this.facilityId) return

      try {
        if (!type || type !== 'refresh') {
          this.loading = true;
        }

        const startDateUTC = moment(new Date(`${this.date} 20:00:00`)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
        const endDateUTC = moment(startDateUTC).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');

        this.params = {
          startDate: startDateUTC,
          endDate: endDateUTC,
          facilityId: this.facilityId
        };

        const data = await sensorLogsService.getSensorLogsDashboard(this.params)

        this.chartData = data?.dataMap;
        this.mappingChartData(data?.dataMap, data?.timesDay);

        this.loading = false;

        const apexChart = this.$refs['apex-chart'];
        if (apexChart) {
          apexChart.$el.querySelector('.exportCSV').replaceWith(apexChart.$el.querySelector('.exportCSV').cloneNode(true));
          apexChart.$el.querySelector('.exportCSV').removeEventListener('click', function (event) { }, true);
          apexChart.$el.querySelector('.exportCSV').addEventListener('click', function (event) {
            vm.exportCSV();
            event.preventDefault();
            event.stopPropagation();
            return;
          });
        }

      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    exportCSV() {
      const vm = this;
      let csvData = [
      ];
      for (let index = 0; index < vm.chartData.length; index++) {
        const serie = vm.chartData[index];
        const datas = serie.data;
        for (let index = 0; index < datas.length; index++) {
          const element = datas[index];
          const d = [
            moment(new Date(element.x)).format("YYYY-MM-DD"),
            moment(new Date(element.x)).format("HH:mm:ss"),
            vm.currentUser.facility?.name || vm.facility?.name || '',
            serie.name,
            element.currentPeopleCount,
          ];
          csvData = [...csvData, d];
        }
      };

      csvData = csvData.sort(function (a, b) {
        return moment(a[1], 'HH:mm:ss') - moment(b[1], 'HH:mm:ss');
      });

      csvData = [
        [
          'YYYY-MM-DD',
          'HH:MM:SS',
          '施設名',
          '部屋',
          '室内人数'
        ],
        ...csvData
      ];

      const facilityName = vm.currentUser.facility?.name || vm.facility?.name || ''

      let fileName = `${facilityName}-${moment(new Date()).format("YYYYMMDDHHmmss")}`;
      let csvContent = "";
      csvData.forEach(function (serie) {
        let row = serie.join(",");
        csvContent += row + "\r\n";
      });

      let csv = document.createElement('a');
      csv.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
      csv.setAttribute('download', fileName);
      csv.click();

    },
    findNodeByInnerHTML(nodelist, innerHTML) {
      let notes = [];
      for (let index = 0; index < nodelist.length; index++) {
        const element = nodelist[index];
        const title = element.querySelector('title').innerHTML;
        if (title === innerHTML) {
          notes = [...notes, element]
        }
      }
      return notes;
    },
    onRemoveDuplicates() {
      const vm = this;
      const apexChart = this.$refs['apex-chart'];
      for (let index = 0; index < 24; index++) {
        let notes = vm.findNodeByInnerHTML(apexChart.$el.querySelectorAll('.apexcharts-xaxis-label'), `${index}${vm.$t('t-time')}`);
        if (notes && notes.length > 1) {
          for (let i = 1; i < notes.length; i++) {
            const e = notes[i];
            e.style.display = 'none';
          }
        }
      }
    },
    onBeforeResetZoom(chartContext, opts) {
      const vm = this;
      const momentDate = moment(`${this.date} 05:00:00`)
      const min = momentDate.toDate().getTime()
      const max = momentDate.add(1, 'days').toDate().getTime()
      vm.chartOptions.xaxis.min = min;
      vm.chartOptions.xaxis.max = max;
      vm.chartOptions.xaxis.tickAmount = 24;

      vm.$refs['apex-chart'].updateOptions(vm.chartOptions);
      this.$refs['apex-chart'].refresh();
      this.onRemoveDuplicates();
      return;
    },
    onScrolled(chartContext, { xaxis }) {
      this.onRemoveDuplicates();
    },
    onZoomed(chartContext, { xaxis, yaxis }) {
      const vm = this;
      vm.chartOptions.xaxis.min = xaxis.min;
      vm.chartOptions.xaxis.max = xaxis.max;

      const minhours = moment(xaxis.min).hours();
      const maxhours = moment(xaxis.max).hours();

      vm.chartOptions.xaxis.tickAmount = (maxhours * 1) - (minhours * 1);

      vm.$refs['apex-chart'].updateOptions(vm.chartOptions);

      vm.onRemoveDuplicates();

    },
    mappingChartData(dataMap, times) {
      const vm = this;
      const sortTimes = times.sort((a, b) => moment(a).unix() - moment(b).unix())
      const hasValue = dataMap.some(d => d.data.length > 0)
      dataMap.forEach((d, idx) => {
        let data = [];
        for (let index = 0; index < sortTimes.length; index++) {
          const time = sortTimes[index];
          const logs = d?.data || [];
          const log = logs.find(logE => logE.x === time);
          if (log) {
            data.push(log);
          } else {
            let prevLog = last(data)
            if (prevLog && !prevLog.deleted) {
              data.push({
                x: time,
                y: prevLog.percentage,
                percentage: prevLog.percentage,
                currentPeopleCount: prevLog.currentPeopleCount
              })
            } else {
              data.push({
                x: time,
                y: 0,
                percentage: 0,
                currentPeopleCount: 0,
                deleted: true,
                dataIndexUnequal: index
              })
            }

          }
        }

        d.data = data.filter(dt => !dt.deleted);
        if (data.some(d => d.deleted)) {
          d.seriesIndexUnequal = idx
          d.plusIndex = data.filter(o => o.deleted).length
        }
        d.originalData = data
      })
      vm.series = hasValue ? dataMap : [];
      const momentDate = moment(new Date(`${this.date} 05:00:00`))
      const min = momentDate.toDate().getTime()
      const max = momentDate.add(1, 'days').toDate().getTime()

      let xaxis = {
        tickAmount: 24,
        type: 'datetime',
        min,
        max,
        labels: {
          show: true,
          formatter: function (val, timestamp) {
            return `${moment(new Date(timestamp)).format("H")}${vm.$t('t-time')}`; // formats to hours:minutes
          }
        }
      }
      vm.chartOptions.xaxis = xaxis;

      const getBackgroundByPercent = (percent) => {
        if (percent < 40) return 'bg-info'
        else if (percent >= 40 && percent < 60) return 'bg-success'
        else if (percent >= 60 && percent < 80) return 'bg-warning'
        else return 'bg-danger'
      }

      vm.chartOptions.tooltip = {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const serie = w.globals.initialSeries[seriesIndex]
          const x = serie.data[dataPointIndex].x;
          let cart = ``;
          for (let index = 0; index < w.globals.initialSeries.length; index++) {
            const element = w.globals.initialSeries[index];
            const dataPointElement = element.originalData.find(d => d.x === x);
            const percentage = dataPointElement.percentage * 1;
            const currentPeopleCount = dataPointElement.currentPeopleCount;

            const color = w.globals.colors[index];
            const colorInfo = getBackgroundByPercent(percentage);
            const textInfo = percentage < 40 ? '空いてる' : (percentage < 60 && percentage >= 40) ? '比較的空いてる' : (60 <= percentage && percentage < 80) ? '少し混雑' : '混雑';
            cart += `<div class="row chart-tooltip mt-1">
              <div class="col-md-5 d-flex align-items-center">
                <div style="min-width: 15px">
                    <div style="background-color: ${color}; width: 10px; height: 10px;border-radius: 50%"></div>
                </div>

                <div class="ml-2 chart-tooltip-name">
                  ${element.name}
                </div>
              </div>
              <div class="col-md-4 fs-14">
                ${currentPeopleCount}${vm.$t('t-name2')}
                ${percentage}%
              </div>
              <div class="col-md-3 text-white chart-tooltip-info ${colorInfo}">
                ${textInfo}
              </div>
            </div>`
          }

          return `
            <div class="card px-1 py-1 mb-0">
              <div class="card-header px-1 py-1 fs-12">${moment(x).format('YYYY-MM-DD HH:mm')}</div>
              <div class="card-body px-1 py-1">
                ${cart}
              </div>
            </div>
          `;
        }
      };

      const facilityName = vm.currentUser.facility?.name || vm.facility?.name || ''

      let toolbar = {
        autoSelected: "zoom",
        tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true, reset: true },
        show: true,
        export: {
          csv: {
            filename: `${facilityName}-${moment(new Date()).format("YYYYMMDDHHmmss")}`,
            headerCategory: 'Timestamp',
            enabled: false,
            dateFormatter(val) {
              return `${moment(new Date(val)).format("YYYY-MM-DD HH:mm:ss")}`;
            }
          },
          svg: {
            filename: `${facilityName}-${moment(new Date()).format("YYYYMMDDHHmmss")}`,
          },
          png: {
            filename: `${facilityName}-${moment(new Date()).format("YYYYMMDDHHmmss")}`,
          },
        }
      };

      vm.chartOptions.chart.toolbar = toolbar;



    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, next);
  }
}
</script>

<style lang="scss">
[data-layout-mode="dark"] {
  .peopleIn, .select-date-picker {
    color: #6691e7 !important;
    background-color: rgba(102, 145, 231, 0.1)  !important;
    border-color: transparent !important;
  }

  .multiselect-caret {
    background: white;
  }
}

.dashboard-chart {
  .select-date-picker {
    width: 110px;
    background-color: #eff4fc;
    color: #6691e3;
    border-color: #eff4fc;
  }

  .peopleIn {
    background-color: #eff4fc;
    color: #6691e3;
    border-color: #eff4fc;
    min-width: 260px;
  }

  .icon-change-date {
    color: #6691e3;
    cursor: pointer;
  }

  .chart-tooltip {
    font-size: 14px;
    color: #878a99;
    width: 350px;
  }

  .chart-tooltip-info {
    width: 75px;
    height: 20px;
    text-align: center;
    font-size: 10px;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chart-tooltip-name {
    // max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  .bgMedium {
    background: #D59231;
  }

  .bgEmpty {
    background: #2D62AE;
  }

  .bgLarge {
    background: #C0272D;
  }

  .bgNormal {
    background: #3B7E44;
  }

  & .card-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  border-radius: 6px;
}
</style>
